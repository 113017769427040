import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import home from '../../assets/sidebar_1.png';
import about from '../../assets/sidebar_2.png';
import contact from '../../assets/sidebar_3.png';
import ContantMe from '../ContactMe';

// import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import loader from '../../assets/icons/loader.gif';

const StyledMenu = styled(motion.div)`
  color: #000;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 0;
  position: absolute;
  z-index: 50;
  overflow: hidden;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: ${props => (props.isVisible ? '30px 20px' : '30px 0')};
  box-sizing: border-box;
  > a {
    display: block;
    padding: 5px 10px;
    text-align: left;
    color: #fff;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    border: 0;
    white-space: nowrap;
  }
  svg {
    width: 25px;
    height: auto;
    filter: brightness(0) invert(1); // to white color
    margin: 0;
  }
  button {
    width: 40px;
    height: 40px;
    margin: 0 auto 30px;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledRectangle = styled.div`
  width: 92%;
  background: #eee;
  border-radius: 8px;
  margin: 5px 10px;
  height: 300px;
  margin-top: 100px;
  @media (max-height: 414px) {
    margin-top: 0;
  }
`;

const StyledPreview = styled(motion.div)`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  right: 0;
  width: 0;
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  height: 100%;
  width: ${props => (props.isVisible ? '80px' : '10px')};
  position: absolute;
  transition:  ${props => (props.isVisible ? '1s 0.5s ease' : '1s 2s ease')};
  right: ${props => (props.isVisible ? '15px' : '0')};
`;

const StyledPreviewContainer = styled(motion.div)`
  background: #e1dddc;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // padding: 5px;
  cursor: pointer;
  border: 5px solid #888888;
  // border-right: none;
  border-radius: ${props => (props.isVisible ? '5px' : '0')};
  min-height: 230px;

  @media (max-width: 768px) {
    min-height: 160px;
  }

  a {
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      transition: all 0.25s;
      width: 80px !important;
    }
  }

  a:hover {
    border-top: 5px solid #7a706d;
    padding: 2px 7px;
    border-bottom: 5px solid #888888;
    box-sizing: border-box;
    img {
      transform: scale(0.9);
    }
  }

  a:first-of-type:hover {
    border-bottom: 5px solid #888888;
    padding: 7px 7px 2px 7px;
    border-top: none;
    box-sizing: border-box;
  }
  a:last-of-type:hover {
    border-top: 5px solid #888888;
    padding: 2px 7px 7px 7px;
    border-bottom: none;
    box-sizing: border-box;
  }
`;

const Menu = ({ menuVisible, setMenuVisible }) => {
  return (
    <React.Fragment>
      <StyledPreview exit={{ marginRight: '-70px' }} isVisible={menuVisible}>
        <StyledPreviewContainer isVisible={menuVisible}>
          <NavLink to="/">
            <img style={{ maxWidth: '100%' }} src={home} alt="loading..." />
          </NavLink>
          <a href="/about">
            <img style={{ maxWidth: '100%' }} src={about} alt="loading..." />
          </a>
          <a href="#">
            <ContantMe>
              <img
                style={{ maxWidth: '100%' }}
                src={contact}
                alt="loading..."
              />
            </ContantMe>
          </a>
        </StyledPreviewContainer>
      </StyledPreview>
    </React.Fragment>
  );
};

export default Menu;
