import React, {useEffect} from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Medium from "../components/Medium";
import ServiceHeader from "../components/ServiceHeader";
import { ReactComponent as IconLambda } from "../assets/icons/lambda_white.svg";
import { datas, pageWidth } from "../config";
import { Size } from "framer";

const ServicesWrap = styled.section`
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  ${pageWidth};
`;

const StyledDatasWrap = styled.section`
  width: 1200px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
  }
  ${pageWidth};
  margin: 30px auto;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const StyledDataWrapper = styled.section`
  width: ${(props) => props.width};
  height: 700px;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
    width: 80% !important;
  }
`;

const StyledData = styled.div`
  cursor: pointer;
  height: ${(props) => props.height};
  margin: 20px;
  background-color: white;
  background-position: center;
  background-size: cover;
  background-image: url('${(props) => props.image}');
`;

const Data = () => {
  const { id } = useParams();
  useEffect(()=>{
    console.log("path~~~~~~~~~~~~", window.location.pathname)
  })
  window.localStorage.setItem("pdfLoaded", "true");
  return (
    <>
      <motion.div
        style={{ width: "100%" }}
        initial={{
          opacity: 0,
        }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <Helmet>
          <title>Da Guo: Data Science</title>
        </Helmet>
        <ServiceHeader text="data" />
        <StyledDatasWrap>
          <StyledDataWrapper width="55%">
            {datas.first.map((item) => {
              const {
                no,
                previewImage,
                image,
                description,
                link,
                livelink,
                modal,
                modal_desc,
                gitlink,
              } = item;
              return (
                <Medium
                  height="330px"
                  key={no}
                  no={no}
                  previewImage={previewImage}
                  image={image}
                  description={description}
                  modal_desc={modal_desc}
                  link={link}
                  livelink={livelink}
                  isOpened={id === no}
                  modal={modal}
                  gitlink={gitlink}
                />
              );
            })}
          </StyledDataWrapper>
          <StyledDataWrapper width="45%">
            {datas.second.map((item) => {
              const {
                no,
                previewImage,
                image,
                description,
                link,
                livelink,
                modal,
                modal_desc,
                gitlink,
              } = item;
              return (
                <Medium
                  height="220px"
                  key={no}
                  no={no}
                  previewImage={previewImage}
                  image={image}
                  description={description}
                  modal_desc={modal_desc}
                  link={link}
                  livelink={livelink}
                  isOpened={id === no}
                  modal={modal}
                  gitlink={gitlink}
                />
              );
            })}
          </StyledDataWrapper>
        </StyledDatasWrap>
      </motion.div>
    </>
  );
};

export default Data;
