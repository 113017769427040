const upcomingText = 'Upcoming';
export { upcomingText };

const pdfLink = '/assets/portfolio.pdf'; // path from 'public' directory
export { pdfLink };

const showMenuIfCursorMovedRightInPixels =
    document.body.clientWidth > 768 ? 200 : 80;
export { showMenuIfCursorMovedRightInPixels };

const pageWidth = `
  width: 80vw;
  max-width: 100vw;
  @media(min-width: 1100px) {
    min-width: 1000px;
  }
  @media (max-width: 1100px) {
    width: 100vw;
  }
`;
export { pageWidth };

const servicesBgImage = false; // '/assets/images/business-code-coding-computer-270360.jpg';
export { servicesBgImage };

const projects = [{
        video: true,
        videoId: "recmDwASkKU",
        title: '.CANs',
        link: 'cans',
        shortDescription: 'A real time soda can object detector using Regions with Convolutional Neural Networks (R-CNN) integrated into Django web framework.',
        previewImage: 'software_cans.jpeg',
        image: ['can1.jpg', 'can2.jpg', 'can3.jpg'], // in /public/assets/images
        tags: ['Python', 'Django', 'Neural Network', 'TensorFlow', 'Object Detection', 'Streaming', 'HTML/CSS', 'Materialize', 'Adobe Creative Suite', 'Duration: 1 week'],
        fullDescription: `<div> Inspired by DeepMind's chess AI, AlphaGo, and its documentary. We (Jin Kang & Da Guo) took the opportunity to challenge ourselves to build a production level object detection model integrated into Django website ready for service, both to learn Python and sate our curiosity.</div>
        <br>
        <div> We bought a bunch of 7oz soda cans and took, in total, 1200 photos and kept 600 in the end. We manually labeled them and fed into the R-CNN Neural Network to train the model.</div>
        <br>
        <div> For more information please visit the GitHub linked below, there you will find a PDF explaining our process.</div>
        <br>`
    },
    {
        video: true,
        videoId: "S02pxRoxssk",
        title: '_V',
        link: 'v',
        shortDescription: 'A first-person tower-defense game developed in Unity3D.',
        previewImage: 'software_v.jpeg',
        image: ['v1.jpg', 'v2.jpg', 'v3.jpg', 'vcontrol.jpg'], // in /public/assets/images
        tags: ['C#', 'Unity3D', 'Rhino3D', 'Blender', "Game Development", 'Adobe Creative Suite', 'Duration: 2 weeks'],
        fullDescription: `<div> As long time gamers, we (Da Guo & Peter Roehl) have always wanted to try making our own games. Combing and mixing genres, we chose to make an unqiue first-person tower-defense game in Unity3D programmed in C#.</div>
        <br>
        <div> The goal of the game is to protect the "Central Computer", the large black box in the middle of the map, from the attacking viruses.</div>
        <br>
        <div> Various weapons including rifles, SMGs, grenade launchers, etc.
        And four types of defense towers, including cannon, missile, laser, and shield towers.
        Four types of custom made enemies, modeled in Rhino3D and rigged in Blender.
        </div>
        <br>
        <div> For more information, please checkout the GitHub link below.
        </div>
        <br>`
    },
    {
        video: true,
        videoId: "453FZirxR04",
        title: 'TotallyNotVirus',
        link: 'totallynotvirus',
        shortDescription: 'A zero-day harmless Trojan virus made to understand computer viruses.',
        previewImage: 'software_not_trojan.jpeg',
        image: [], // in /public/assets/images
        tags: [
            'JavaScript',
            'Cyber Security',
            'Trojan Virus',
            'Silent Install',
            'HTML/CSS',
            'Command Line Manipulation'
        ],
        fullDescription: `<div> In recent years, cyber security has become more of a hot topic. We (Da Guo & Stephen Holms) have always been curious in how computer viruses actually work. We took this opportunity to investigate and built an harmless Trojan which silently installs a text file onto C disk and runs it, simulating real-world scenario.</div>
        <br>
        <div>The Trojan was able to bypass native windows security and external antivirus programs (NOD32, etc). </div>
        <br>
        <div>The video on the left demonstrates the process and result.
        <br>
            For ethical concerns, we chose to not to put this on GitHub even though it's simple and harmless.
        </div>
        <br>`
    }
];
export { projects };

const datas = {
    first: [{
            no: "Classification: Steam Game Sales Prediction",
            title: "Predicting if Steam games would go on Sale",
            previewImage: "data_asset1.png",
            image: "data_content_image1.png",
            modal: true,
            link: "https://medium.com/ai-in-plain-english/supervised-machine-learning-classification-predicting-possibility-of-steam-game-to-be-on-sale-a0043d0c5ba5",
            gitlink:'https://github.com/opophehu/classification_Steam_Discount',
            livelink: "https://steam-discount-predictor.herokuapp.com/",
            description: `
            <div> A supervised classification model to predict if a stategy game on Steam would go on sale using scraped data. A machine learning model is deloyed in a flask app as live service.</div>
            <br>`,
            // modal_desc:  `
            // <div> A supervised classification model to predict if a stategy game on Steam would go on sale.</div>
            // <br>`,
            modal_desc:`
            A supervised classification model to predict if a stategy game on Steam would go on sale. Data is collect by scraping official Steam website using BeautifulSoup. The data is a snapshot of the sale condition on 4/24/2020, with about 13,000 observations. The final model is done in Gradient Boosting, with ROC of 0.71 and F1 of 0.27 .
            For more information, please click on the above picture to see the Medium publication.
            `
        },
        {
            no: "Hybrid: Traffic Congestion Anomaly Detection",
            title: "Traffic Congestion Anomaly Detection",
            previewImage: "p5.jpg",
            image: "p5.jpg",
            modal: true,
            link: "https://medium.com/@opophehu/placeholder-for-traffic-congestion-972873feee8a",
            gitlink:'https://github.com/opophehu/traffic_congestion_anomaly_detection',
            description: `
            <div> A project aimed at understanding traffic congestions in terms of duration and its anomalies primarily in WA state with scalability in mind using pyspark.
            <br>
            </div>
            <br>`,            
            modal_desc: ` 
            A project aimed at understanding traffic congestions in terms of duration and its anomalies primarily in WA state with scalability in mind using pyspark.

            Dataset is sourced from a recent research paper, it contains 25 million traffic event and 6 million weather event observations, with population data gathered from US Census Bureau. 

            The project is twofold: a regression model to predict the duration of congestion if it happens, leading into a anomaly detector to see if early intervention is necessary.    
            For more information, please click on the above picture to see the Medium publication.
            `
        },
    ],
    second: [{
            no: "EDA: Maximizing Audience Reach for Women in Tech",
            title: "EDA: Maximizing Audience Reach for Women in Tech",
            previewImage: "data_asset2.png",
            image: "data_content_image2.png",
            modal: true,
            link: "https://medium.com/analytics-vidhya/exploratory-data-analysis-maximizing-audience-reach-for-women-in-tech-398099e89d0",
            gitlink: "https://github.com/opophehu/WTWY_Summer2020_EDA",
            description: `
            <div> An exploratory data analysis project focusing on maximizing audience reach for a fictional organization using NY subway data.</div>
            <br>`,            
            modal_desc: ` 
            An exploratory data analysis project focusing on maximizing audience reach for a fictional organization using NY subway data.    
            This is a three person collaboration project. Demographic data was scraped to target specific population based on need. We primarily used pandas to group and clean the dataset to draw insights from it. As a result, we recommanded three locations for the organization's survey team to go to for the best result per man power. For more information, please click on the above picture to see the Medium publication.
            `
        },
        {
            no: "Linear Regression: Steam Discount Magnitude Prediction",
            title: "Predicting Magnitude of Steam Discount",
            previewImage: "data_asset3.png",
            image: "data_content_image3.png",
            modal: true,
            link: "https://medium.com/analytics-vidhya/linear-regression-predicting-the-magnitude-of-steam-discount-1cd77591fcaa",
            gitlink: "https://github.com/opophehu/LR_Steam",
            livelink: "https://steam-discount-predictor.herokuapp.com/",
            description: `
            <div> A project seeks to understand what drives the discount percentage of games on Steam. This is the predecessor to the Steam classification project. A machine learning model is deloyed in a flask app as live service.</div>
            <br>`,            
            modal_desc: ` 
            This is a project about understanding what impacts the magnitude of game discount on Steam in the strategy genre and build a linear regression model to predict the outcome. Data is scraped from official Steam website using BeautifulSoup. The final model is done in Lasso regression, with a R^2 of 0.42 +- 12%.
            For more information, please click on the above picture to see the Medium publication.
            `
        },
        {
            no: "NLP: Covid-19 Mask Sentiment Analysis in the USA",
            title: "NLP: Covid-19 Mask Sentiment Analysis in the USA",
            previewImage: "data_asset4.png",
            image: "data_content_image4.png",
            modal: true,
            link: "https://medium.com/ai-in-plain-english/unsupervised-machine-learning-clustering-analyzing-covid-19-mask-sentiment-in-the-usa-a104170ad9a7",
            gitlink: "https://github.com/opophehu/covid19_mask_sentiment",
            livelink: "https://covid19-mask-sentiment.herokuapp.com/",
            description: `
            <div> An sentiment analysis inspired by the recent Covid-19 mask controversy in the USA using Twitter data.</div>
            <br>`,            
            modal_desc: ` 
            An sentiment analysis inspired by the recent Covid-19 mask controversy in the USA using Twitter data. The tweets were collected by streaming from Twitter API, then put into an online MongoDB database, with "mask" as keyword. Through Natural Language Processing and topic modeling, three groups were identified with their traits defined. A live sentiment tracker is integrated into the dashboard app and deployed.
            For more information, please click on the above picture to see the Medium publication.
            `
        },
    ]
}
export { datas }