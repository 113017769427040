import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { motion } from 'framer-motion';
import Service from "../components/Service";
import Project from "../components/Project";
import ServiceHeader from "../components/ServiceHeader";
import { ReactComponent as IconLambda } from "../assets/icons/lambda_white.svg";
import { projects, pageWidth } from "../config";

const ServicesWrap = styled.section`
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  ${pageWidth};
`;

const StyledProjectsWrap = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
  }
  ${pageWidth};
  margin: 30px auto;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const Software = () => {
  const { id } = useParams();

  window.localStorage.setItem("pdfLoaded", "true");
  return (
    <>
      <motion.div
        style={{ width: "100%" }}
        initial={{
          opacity: 0,
        }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <Helmet>
          <title>Da Guo: Software</title>
        </Helmet>
        <ServiceHeader text="software" />
        <ServicesWrap>
          <Service icon={<IconLambda />} text="Software Development" />
        </ServicesWrap>
        <StyledProjectsWrap>
          {projects.map((item) => {
            const {
              title,
              previewImage,
              image,
              tags,
              shortDescription,
              fullDescription,
              link,
              video,
              videoId
            } = item;
            return (
              <Project
                key={title}
                title={title}
                previewImage={previewImage}
                image={image}
                tags={tags}
                description={shortDescription}
                fullDescription={fullDescription}
                link={link}
                isOpened={id === link}
                video={video}
                videoId={videoId}
              />
            );
          })}
        </StyledProjectsWrap>
      </motion.div>
    </>
  );
};

export default Software;
