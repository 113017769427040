import React from 'react';
import styled from 'styled-components';
import youtube from '../../assets/icons/youtube.png';
import github from '../../assets/icons/github.png';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton, TwitterIcon,
  TwitterShareButton,

} from 'react-share';

const StyledShared = styled.section`
  > button {
  margin-top: 10px;
  margin-right: 10px;
  }
`;

const StyledIcon = styled.div`

  margin-top: 10px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  display: inline-block;
  
  > img {
    max-width: 100%;
  }
`;

const Share = ({title}) => {
  const shareUrl = window.location.href;

  return (
    <StyledShared>
      <a href="https://www.youtube.com/channel/UCPCOxB6147d4LDsltIvvf5A?view_as=subscriber" target="opophehu">
        <StyledIcon>
          <img src={youtube} />
        </StyledIcon>
      </a>
      <a href="https://github.com/opophehu?tab=repositories" target="opophehu">
      <StyledIcon>
        <img src={github} />
      </StyledIcon>
      </a>
      {/*<FacebookShareButton*/}
      {/*  url={shareUrl}*/}
      {/*  quote={title}*/}
      {/*  className="share-button"*/}
      {/*>*/}
      {/*  <FacebookIcon size={32} round />*/}
      {/*</FacebookShareButton>*/}
      {/*<LinkedinShareButton*/}
      {/*  url={shareUrl}*/}
      {/*  quote={title}*/}
      {/*  className="share-button"*/}
      {/*>*/}
      {/*  <LinkedinIcon size={32} round />*/}
      {/*</LinkedinShareButton>*/}
      {/*<TwitterShareButton*/}
      {/*  url={shareUrl}*/}
      {/*  quote={title}*/}
      {/*  className="share-button"*/}
      {/*>*/}
      {/*  <TwitterIcon size={32} round />*/}
      {/*</TwitterShareButton>*/}
      {/*<EmailShareButton*/}
      {/*  url={shareUrl}*/}
      {/*  body={`${title} : ${shareUrl}`}*/}
      {/*  subject={title}*/}
      {/*  quote={title}*/}
      {/*  className="share-button"*/}
      {/*>*/}
      {/*  <EmailIcon size={32} round />*/}
      {/*</EmailShareButton>*/}
    </StyledShared>
  );
};

export default Share;
