import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Helmet from "react-helmet";

// import Service from '../components/Service';
import { Frame } from "framer";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconA } from "../assets/icons/a.svg";
import { ReactComponent as IconLambda } from "../assets/icons/lambda.svg";
import { ReactComponent as IconData } from "../assets/icons/data.svg";
import { pageWidth, servicesBgImage, upcomingText } from "../config";

const ServicesBackground = styled(motion.div)`
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
`;

const ServicesWrap = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${pageWidth};
  & > * {
    width: 33.33% !important;
    min-width: 330px !important;
  }
  position: relative;
`;
const ServicesBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 1.2s ease-in;
  background-position: center center;
  opacity: ${(props) => (props.ishover ? 1 : 0)};
  transform: skew(-25deg);
  overflow: hidden;
  &:after {
    content: none;
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    transform: skewX(25deg);
    background: transparent;
  }
`;

const StyledBorder = styled(motion.div)`
  display: block;
  height: 250px;
  width: 1px;
  background: #58595b;
  position: absolute;
  top: -10px;
  right: 0;
  z-index: 30;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledService = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 333px;
  max-width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
  margin: 0;
  box-sizing: border-box;
  ${(props) => (props.css.length > 0 ? props.css : "")};
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 1s ease-in;
    background: ${(props) => (props.ishover ? "#d3d3d3" : "transparent")};
    z-index: 11;
    @media (min-width: 768px) {
      -webkit-transform: skew(-25deg);
      -moz-transform: skew(-25deg);
      -o-transform: skew(-25deg);
    }
  }
`;

const StyledIcon = styled(motion.div)`
  z-index: 15;
  & svg {
    height: 100px;
    position: relative;
  }
`;

const StyledText = styled(Frame)`
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: -0.04em;
  display: flex !important;
  justify-content: center;
  position: absolute;
  top: 40px;
  z-index: 20;
  transition: all 0.8s;
  opacity: ${(props) => (props.ishover ? 1 : 0)};
  @media (max-width: 768px) {
    opacity: 1 !important;
  }
  > span {
    min-width: 5px;
  }
`;

const StyledSubText = styled(motion.div)`
  color: #696969;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  z-index: 400;
  font-size: 60px;
`;

const StyledBackground = styled(motion.div)`
  position: absolute;
  z-index: 12;
  transition: all 0.8s ease-in;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    -webkit-transform: skew(-25deg);
    -moz-transform: skew(-25deg);
    -o-transform: skew(-25deg);
  }
`;

const QuoteArchitecture = styled.div`
  width: 66.66% !important;
  height: ${(props) => (props.visible ? "100%" : "0")};
  transition: all 1s;
  display: flex;
  overflow: hidden;
  right: 0;
  position: absolute;
  z-index: 500;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    display: none !important;
  }
`;

const QuoteSoftware = styled.div`
  width: 33.33% !important;
  height: ${(props) => (props.visible ? "100%" : "0")};
  transition: all 1s;
  display: flex;
  overflow: hidden;
  right: 0;
  position: absolute;
  z-index: 500;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    display: none !important;
  }

  > div {
    position: absolute;
    right: 15%;
    bottom: 15%;
  }
`;

const QuoteData = styled.div`
  width: 66.66% !important;
  height: ${(props) => (props.visible ? "100%" : "0")};
  transition: all 1s;
  display: flex;
  overflow: hidden;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 500;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    display: none !important;
  }
`;

const StyledBg = styled.div`
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  overflow: hidden;
  z-index: 400;
  transition: opacity 1s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  -webkit-transform: skew(-25deg);
  -moz-transform: skew(-25deg);
  -o-transform: skew(-25deg);
  @media (max-width: 768px) {
    -webkit-transform: skew(0);
    -moz-transform: skew(0);
    -o-transform: skew(0);
  }
  background-color:  ${(props)=> (props.color ? "#fff" : "#000")};
  &:after {
    opacity:${(props)=> (props.color ? "0.3" : "1")};
    content: '';
    position: absolute;
  top: -16%;
  left: -16%;
  width: 132%;
  height: 132%;
    -webkit-transform: skew(25deg);
    -moz-transform: skew(25deg);
    -o-transform: skew(25deg);
    @media (max-width: 768px) {
    -webkit-transform: skew(0);
    -moz-transform: skew(0);
    -o-transform: skew(0);
  }
  
  background-size: cover;
  background-position: center;
  -webkit-transition: background-image 1s;
transition: background-image 1s;
  background-image: url('${(props) => props.bg}');
  }
`;

const Services = () => {
  const [isHover, setHover] = useState(false);
  const [isHoverArchitecture, setHoverArchitecture] = useState(false);
  const [isHoverSoftware, setHoverSoftware] = useState(false);
  const [isHoverData, setHoverData] = useState(false);
  const [isHoverDataDisabled, setHoverDataDisabled] = useState(false);
  window.localStorage.setItem("pdfLoaded", "true");

  const [arcBg, setArcBg] = useState("/assets/images/architecture_bg.png");
  const [sortwareBg, setSortwareBg] = useState("/assets/images/black_bg.png");
  const [dataBg, setDataBg] = useState("/assets/images/data_bg.png");

  // eslint-disable-next-line no-nested-ternary
  // const arcBg = isHoverSoftware ? '/assets/images/architecture_bg.png' : isHoverData ? '/assets/images/data_bg.png' : 'assets/images/architecture_bg.png';

  const serviceCss = `
    padding: 60px 0; 
    height: 230px;
  `;
  return (
    <ServicesBackground>
      <ServicesWrap
        onHoverStart={() => {
          setHover(true);
        }}
        onHoverEnd={() => {
          setHover(false);
        }}
      >
        <>
          <QuoteArchitecture visible={isHoverArchitecture}>
            <span>
              <span style={{ fontSize: 22 }}>
                "Simplicity is the ultimate sophistication"
              </span>{" "}
              - Leonardo da Vinci
            </span>
          </QuoteArchitecture>
          <QuoteSoftware visible={isHoverSoftware}>
            <span style={{ fontSize: 22 }}>
              "Perfection is archived not when <br /> there is nothing more add,
              but <br /> rather when there is nothing more <br /> to take away"
            </span>
            <div> - Antoine de Saint-Exupery</div>
          </QuoteSoftware>
          <QuoteData visible={isHoverData}>
            <span style={{color: "black"}}>
              <span style={{ fontSize: 22 }}>
                "The most valuable commodity I know of is information"
              </span>{" "}
              - Charles Babbage
            </span>
          </QuoteData>
          {isHover && (
            <Helmet bodyAttributes={{ style: "background-color: #7C7C7C" }} />
          )}
          {/* <a href={'/architecture'}> */}
          <NavLink to="/architecture">
            <StyledService
              onHoverStart={() => {
                setHoverArchitecture(true);
                setSortwareBg("/assets/images/architecture_1.png");
                setDataBg("/assets/images/architecture_2.png");
              }}
              onHoverEnd={() => {
                setHoverArchitecture(false);
              }}
              css={serviceCss}
              ishover={isHoverArchitecture}
            >
              <StyledBg
                bg={arcBg}
                visible={isHoverSoftware || isHoverData}
                color={isHoverData}
              />
              <StyledIcon
                initial={{
                  scale: 1,
                }}
                animate={{
                  scale: isHoverArchitecture ? 1.2 : 1,
                  opacity: isHover && !isHoverArchitecture ? 0 : 1,
                }}
                exit={{
                  scale: 0.3,
                }}
                transition={{ duration: 1.2 }}
              >
                <IconA />
              </StyledIcon>
              <StyledText
                center={"y"}
                height={26}
                width={"100%"}
                background={""}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: isHoverArchitecture ? 1 : 0,
                }}
                transition={{ duration: 0.2 }}
              >
                {Array.from("Architecture").map((letter, index) => (
                  <span
                    key={`${letter}${index}${
                      Array.from("Architecture").length
                    }`}
                  >
                    {letter}
                  </span>
                ))}
              </StyledText>

              {false && (
                <StyledSubText ishover={isHoverArchitecture ? 1 : 0}>
                  {upcomingText}
                </StyledSubText>
              )}
              {true && !isHover && (
                <StyledBorder transformTemplate={`rotate(25deg)`} />
              )}
              {true && (
                <StyledBackground ishover={1} transition={{ duration: 1 }} />
              )}
            </StyledService>
            {/* </a> */}
          </NavLink>
          {/* <a href={'/software'}> */}
          <NavLink to="/software">
            <StyledService
              onHoverStart={() => {
                setHoverSoftware(true);
                setArcBg("/assets/images/architecture_bg.png");
                setDataBg("/assets/images/data_bg.png");
              }}
              onHoverEnd={() => {
                setHoverSoftware(false);
              }}
              css={serviceCss}
              ishover={isHoverSoftware}
            >
              <StyledBg
                bg={sortwareBg}
                visible={isHoverArchitecture || isHoverData}
                color={isHoverData}
              />
              <StyledIcon
                initial={{
                  scale: 1,
                }}
                animate={{
                  scale: isHoverSoftware ? 1.2 : 1,
                  opacity: isHover && !isHoverSoftware ? 0 : 1,
                }}
                exit={{
                  scale: 0.3,
                }}
                transition={{ duration: 1.2 }}
              >
                <IconLambda />
              </StyledIcon>
              <StyledText
                center={"y"}
                height={26}
                width={"100%"}
                background={""}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: isHoverSoftware ? 1 : 0,
                }}
                transition={{ duration: 0.2 }}
              >
                {Array.from("Software Development").map((letter, index) => (
                  <span
                    key={`${letter}${index}${
                      Array.from("Software Development").length
                    }`}
                  >
                    {letter}
                  </span>
                ))}
              </StyledText>

              {false && (
                <StyledSubText ishover={isHoverSoftware ? 1 : 0}>
                  {upcomingText}
                </StyledSubText>
              )}
              {true && !isHover && (
                <StyledBorder transformTemplate={`rotate(25deg)`} />
              )}
              {true && (
                <StyledBackground
                  bg={"/assets/images/data_bg.png"}
                  ishover={isHoverSoftware && !isHover ? 1 : 0}
                  transition={{ duration: 1 }}
                />
              )}
            </StyledService>
            {/* </a> */}
          </NavLink>
          <NavLink to="/data">
            <StyledService
              onHoverStart={() => {
                setHoverData(true);
                setHoverDataDisabled(true);
                setArcBg("/assets/images/bg_data2.png");
                setSortwareBg("/assets/images/bg_data1.png");
              }}
              onHoverEnd={() => {
                setHoverData(false);
                setHoverDataDisabled(false);
              }}
              css={serviceCss}
              ishover={isHoverData}
            >
              <StyledBg
                bg={dataBg}
                visible={isHoverArchitecture || isHoverSoftware}
              />
              <StyledIcon
                initial={{
                  scale: 1,
                }}
                animate={{
                  scale: isHoverData ? 1.2 : 1,
                  opacity: isHover && !isHoverData ? 0 : 1,
                }}
                exit={{
                  scale: 0.3,
                }}
                transition={{ duration: 1.2 }}
              >
                <IconData />
              </StyledIcon>
              <StyledText
                center={"y"}
                height={26}
                width={"100%"}
                background={""}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: isHoverData ? 1 : 0,
                }}
                transition={{ duration: 0.2 }}
              >
                {Array.from("Data science").map((letter, index) => (
                  <span
                    key={`${letter}${index}${
                      Array.from("Data science").length
                    }`}
                  >
                    {letter}
                  </span>
                ))}
              </StyledText>

              {/* <StyledSubText
                initial={{
                  fontSize: '75x',
                  fontWeight: 700,
                  opacity: isHoverDataDisabled ? 0 : 0,
                  left: '-133.33%'
                }}
                animate={{
                  fontSize: isHoverDataDisabled ? '75px' : '75px',
                  fontWeight: isHoverDataDisabled ? 700 : 700,
                  opacity: isHoverDataDisabled ? 1 : 0,
                  left: '-133.33%'
                }}
                transition={{
                  delay: isHoverDataDisabled ? 1 : 0,
                  duration: isHoverDataDisabled ? 1 : 0
                }}
                ishover={isHoverData}
              >
                {upcomingText}
              </StyledSubText> */}
              {false && !isHover && (
                <StyledBorder transformTemplate={`rotate(25deg)`} />
              )}
              {true && (
                <StyledBackground
                  bg={"/assets/images/data_bg.png"}
                  ishover={isHoverData && !isHover ? 1 : 0}
                  transition={{ duration: 1 }}
                />
              )}
            </StyledService>
          </NavLink>
        </>
        <ServicesBg
          servicesBgImage="https://www.w3schools.com/w3images/lights.jpg"
          ishover={isHover}
        />
      </ServicesWrap>
    </ServicesBackground>
  );
};

export default Services;
