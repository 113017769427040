import React, { useState } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { motion } from 'framer-motion';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import YouTube from 'react-youtube';
import { Slide } from 'react-slideshow-image';
import Share from '../Share';

const StyledProject = styled(motion.div)`
  background: #fff;
  color: #58595b;
  // border: 1px solid #eee;
  margin-bottom: 10px;
  width: 32%;
  min-width: 300px;
  height: 220px;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  transition: all 0.2s;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  &:hover {
    background-image: none;
    box-shadow: 10px 10px 15px -10px rgba(88, 89, 91, 0.45);
    &:before {
      opacity: 0;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all 1s;
    background-size: cover;
    background-position: center;
    background-image: url('${props => props.image}');
  }
`;

const pixelsToMove = 100;

const cssVisible = `
  opacity: 1;
  top: 0;
`;
const cssHidden = `
  opacity: 0;
`;

const StyledTitle = styled.h3`
  color: #000;
  font-size: 22px;
  font-weight: 500;
  position: relative;
  transition: all 1s;
  margin: 0;
  margin-bottom: 15px;
  ${props =>
    !props.ishover
      ? `
      ${cssVisible}
  `
      : `position: absolute;
      top: -${pixelsToMove}px;
      ${cssHidden}`}
`;

const StyledDescription = styled.div`
  color: #000;
  font-size: 16px;
  max-width: 90%;
  max-height: 80%;
  position: relative;
  transition: all 0.7s;
  color: #a0a1a0;
  ${props =>
    props.ishover
      ? `
      ${cssVisible}
      position: relative;
      transform: scale(1);
      `
      : `
  ${cssHidden}
      position: absolute;
      transform: scale(0);
  `};
  @media (max-width: 768px) {
    position: relative;
    opacity: 1;
    transform: scale(1);
  }
`;

// Modal
Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    zIndex: '30',
    background: 'rgba(0,0,0,0.7)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
    position: 'fixed',
    minWidth: '20rem',
    width: '80%',
    maxWidth: '80rem',
    minHeight: '5rem',
    height: '60vh',
    maxHeight: '60rem'
  }
};

const StyledClose = styled.button`
  background: transparent;
  border: 1px solid transparent;
  font-size: 35px;
  font-weight: 500;
  position: fixed;
  top: 15px;
  right: 15px;
  color: #58595b;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    border-color: #eee;
  }
`;

const StyledModal = styled(Modal)`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  overflow-y: scroll;
  background: #fff;
`;

const StyledModalContent = styled.section`
  color: #58595b;
  font-size: 22px;
  font-weight: 500;
  background: #fff;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  // padding-top: 30px;
  & h2 {
    text-align: center;
    margin-top: 0;
    padding-top: 0;
  }
  height: 100%;
`;

const StyledImageWrapper = styled.div`
  width: 50%;
  height: 100%;
  background-size: cover;
  background-image: url('${props => props.image}');
  div {
    height: 100%;
  }
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
    margin-top: 30px;
  }
`;

const StyledVideoWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    flex: 1;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
    margin-top: 30px;
    > div {
      > iframe {
        max-height: 200px;
      }
    }
  }
`;

// const StyledImage = styled.img`
//   width: 50%;
//   object-fit: cover;
//   height: auto;
//   @media (max-width: 768px) {
//     width: 100%;
//     height: 300px;
//     margin-bottom: 30px;
//   }
// `;

const StyledContent = styled.section`
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  padding-left: 40px;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 20px;
    width: 100%;
    padding: 0;
  }
`;

const StyledTagsWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const StyledTag = styled.div`
  font-weight: 300;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 10px;
  background: #929292;
  padding: 0 15px 2px;
  border-radius: 20px;
  color: #fff;
`;

const properties = {
  // duration: 5000,
  // transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  pauseOnHover: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  },
  autoplay: false
};

const Project = ({
  title,
  description,
  fullDescription,
  previewImage,
  image,
  tags,
  link,
  isOpened,
  video,
  videoId
}) => {
  const [isHover, setHover] = useState(false);
  const [modalOpen, setModalOpen] = useState(isOpened);

  const history = useHistory();

  const openModal = () => {
    setModalOpen(true);
    // history.push(`/software/${link}`);
  };
  const closeModal = () => {
    setModalOpen(false);
    // setTimeout(() => {
    //   history.push(`/software/`);
    // }, 1000);
  };
  const onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const wrapperArr = [];
  if (video) {
    wrapperArr.push(
      <div className="each-slide" key="video">
        <YouTube
          videoId={videoId}
          opts={{
            width: '100%',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0
            }
          }}
          onReady={onReady}
        />
      </div>
    );
  }
  image.forEach(img => {
    wrapperArr.push(
      <div className="each-slide" key={link}>
        <div style={{ backgroundImage: `url("/assets/images/${img}")` }}>
          <span> </span>
        </div>
      </div>
    );
  });

  return (
    <>
      <StyledProject
        onHoverStart={() => {
          setHover(true);
        }}
        onHoverEnd={() => {
          setHover(false);
        }}
        image={`/assets/images/${previewImage}`}
        onClick={openModal}
      >
        <StyledTitle ishover={isHover ? 0 : 1}>{title}</StyledTitle>
        <StyledDescription ishover={isHover ? 1 : 0}>
          {parse(description)}
        </StyledDescription>
      </StyledProject>
      <StyledModal
        isOpen={modalOpen}
        onAfterOpen={() => {}}
        onRequestClose={closeModal}
        contentLabel={title}
        style={customStyles}
        closeTimeoutMS={700}
      >
        {modalOpen && <StyledClose onClick={closeModal}>&times;</StyledClose>}
        <StyledModalContent>
          {video && image.length === 0 ? (
            <StyledVideoWrapper>
              <YouTube
                videoId={videoId}
                opts={{
                  width: '100%',
                  playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1
                  }
                }}
                onReady={onReady}
              />
            </StyledVideoWrapper>
          ) : video && image.length > 0 ? (
            <StyledImageWrapper>
              <Slide {...properties}>{wrapperArr}</Slide>
            </StyledImageWrapper>
          ) : !video && image.length > 1 ? (
            <StyledImageWrapper>
              <Slide {...properties}>{wrapperArr}</Slide>
            </StyledImageWrapper>
          ) : (
            <StyledImageWrapper image={`/assets/images/${image}`} />
          )}

          <StyledContent>
            <h2>{title}</h2>
            <StyledTagsWrap>
              {tags.map(item => {
                return <StyledTag key={item}>{item}</StyledTag>;
              })}
            </StyledTagsWrap>
            <div>{parse(fullDescription)}</div>
            <Share title={title} />
          </StyledContent>
        </StyledModalContent>
      </StyledModal>
    </>
  );
};

export default Project;
