import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Frame } from 'framer';
import { motion } from 'framer-motion';
import { ReactComponent as IconArchitecture } from '../../assets/icons/a.svg';
import { ReactComponent as IconLambda } from '../../assets/icons/lambda_white.svg';
import { ReactComponent as IconDataScience } from '../../assets/icons/data.svg';

const StyledBorder = styled(motion.div)`
  display: block;
  height: 250px;
  width: 1px;
  background: #58595b;
  position: absolute;
  top: -10px;
  right: 0;
  z-index: 30;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledServiceHeader = styled(motion.div)`
  position: absolute;
  top: 40px;
  left: 100px;
`;

const StyledIcon = styled(motion.div)`
  z-index: 15;
  float: left;
  margin-right: 10px;
  margin-left: 10px;
  & svg {
    height: 30px;
    position: relative;
    & polygon {
      fill: #58595b !important;
    }
    & rect {
      fill: #58595b !important;
    }
    opacity: 0.5
  }
`;

const StyledActiveIcon = styled(motion.div)`
  z-index: 15;
  float: left;
  margin-right: 10px;
  margin-left: 10px;
  & svg {
    height: 30px;
    position: relative;
    & polygon {
      fill: #58595b !important;
    }
    & rect {
      fill: #58595b !important;
    }
  }
`;

const StyledText = styled.div`
  float: left;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  z-index: 20;
  bottom: -10px;
  position: relative;
`;

const StyledSubText = styled.div`
  color: #848484;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 60px;
  position: absolute;
  transition: all 1s;
  transition-delay: 0.3s;
  z-index: 12;
  opacity: ${props => (props.ishover ? 1 : 0)};
`;

const StyledBackground = styled(motion.div)`
  position: absolute;
  z-index: 12;
  transition: all 0.8s ease-in;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    -webkit-transform: skew(-25deg);
    -moz-transform: skew(-25deg);
    -o-transform: skew(-25deg);
  }
`;

const Service = ({ text }) => {
  const textString = Array.from(text);
  const [active, setActive] = useState(text);
  useEffect(() => {
    setActive(text);
    console.log("dfasfd")
  });
  return (
    <>
      <StyledServiceHeader>
        {active === 'architecture' ? (
          <a href="/architecture">
            <StyledActiveIcon>
              <IconArchitecture />
            </StyledActiveIcon>
            <StyledText
              center={'y'}
              height={26}
              background={''}
              initial={{
                opacity: 1
              }}
            >
              architecture
            </StyledText>
          </a>
        ) : (
          <NavLink to="/architecture">
            <StyledIcon>
              <IconArchitecture />
            </StyledIcon>
          </NavLink>
        )}

        {active === 'software' ? (
          <NavLink to="/software">
            <StyledActiveIcon>
              <IconLambda />
            </StyledActiveIcon>
            <StyledText
              center={'y'}
              height={26}
              background={''}
              initial={{
                opacity: 1
              }}
            >
              software development
            </StyledText>
          </NavLink>
        ) : (
          <NavLink to="/software">
            <StyledIcon>
              <IconLambda />
            </StyledIcon>
          </NavLink>
        )}

        {active === 'data' ? (
          <NavLink to="/data">
            <StyledActiveIcon>
              <IconDataScience />
            </StyledActiveIcon>
            <StyledText
              center={'y'}
              height={26}
              background={''}
              initial={{
                opacity: 1
              }}
            >
              data science
            </StyledText>
          </NavLink>
        ) : (
          <NavLink to="/data">
            <StyledIcon>
              <IconDataScience />
            </StyledIcon>
          </NavLink>
        )}
      </StyledServiceHeader>
    </>
  );
};

export default Service;
