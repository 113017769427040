import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import Helmet from 'react-helmet';

const StyledInfo = styled('div')`
  display: flex;
  flex: 1;
  padding: 0 30px;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledDivider = styled('div')`
  height: 100%;
  width: 0px;
  @media(max-width: 1024px) {
    width: 100%;
    height: 0px;
  }
  border: 2px solid #888888;
`;

const StyledDescription = styled('div')`
  display: flex;
  flex: 1;
  padding: 0 30px;
  @media(max-width: 1024px) {
    margin-top: 30px;
  }
  flex-direction: column;
  justify-content: space-between;
  // border: 1px solid blue;
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  @media(max-width: 1024px) {
  flex-direction: column;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
  display: none;
  }
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
`;

const Title = styled.div`
  color: #222;
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledInput = styled.div`
  position:relative; 
  margin-top: 45px; 
  margin-left: 25px;
  // border: 1px solid red;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  @media(max-width: 1024px) {
    width: 100%;
  }
  input {
    background: transparent;
    font-size:18px;
    padding:10px 10px 10px 5px;
    display:block;
    width: calc(100% - 15px);
    border:none;
    border-bottom:1px solid #757575;
  }
  input:focus { outline:none; }
  
  label {
    color:#888888!important; 
    font-size:24px;
    font-weight: 400;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:5px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }

  input:focus ~ label, input:valid ~ label {
    top:-25px;
    left: -25px;
    @media(max-width: 1024px) {
      left: 0;
    }
    font-size:20px;
    color:#5264AE;
  }

  .bar { position:relative; display:block; width: 100%; }
  .bar:before, .bar:after {
    content:'';
    height:2px; 
    width:0;
    bottom:1px; 
    position:absolute;
    background:#888888; 
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .bar:before {
    left:50%;
  }
  .bar:after {
    right:50%; 
  }
  
  input:focus ~ .bar:before, input:focus ~ .bar:after {
    width:50%;
  }
  
  .highlight {
    position:absolute;
    height:60%; 
    width:100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }

  input:focus ~ .highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
`;

const StyledCheckbox = styled.div`
  width: 70%;
  @media(max-width: 1024px) {
    width: 100%;
  }
  margin-top: 45px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  .pure-material-checkbox {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    font-size: 16px;
    line-height: 1.5;
}

/* Input */
.pure-material-checkbox > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -8px;
    top: 1px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pure-material-checkbox > span {
    display: inline-block;
    width: 100%;
    font-size: 20px;
    cursor: pointer;
    color: #888888;
}

/* Box */
.pure-material-checkbox > span::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    margin: 10px 11px 3px 1px;
    border: solid 2px; /* Safari */
    border-color: #888888;
    border-radius: 2px;
    width: 12px;
    height: 12px;
    vertical-align: top;
    transition: border-color 0.2s, background-color 0.2s;
    background: #888888;
}

/* Checkmark */
.pure-material-checkbox > span::after {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: -2px;
    width: 9px;
    height: 5px;
    border: solid 2px transparent;
    border-right: none;
    border-top: none;
    transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.pure-material-checkbox > input:checked,
.pure-material-checkbox > input:indeterminate {
    background-color: #888888;
}

.pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
    border-color: #888888;
    background-color: #888888;
}

.pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
    border-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
}

.pure-material-checkbox > input:indeterminate + span::after {
    border-left: none;
    transform: translate(4px, 3px);
}

/* Hover, Focus */
.pure-material-checkbox:hover > input {
    opacity: 0.04;
}

.pure-material-checkbox > input:focus {
    opacity: 0.12;
}

.pure-material-checkbox:hover > input:focus {
    opacity: 0.16;
}

/* Active */
.pure-material-checkbox > input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.pure-material-checkbox > input:active + span::before {
    border-color: #888888
}

.pure-material-checkbox > input:checked:active + span::before {
    border-color: transparent;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
}

/* Disabled */
.pure-material-checkbox > input:disabled {
    opacity: 0;
}

.pure-material-checkbox > input:disabled + span {
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    cursor: initial;
}

.pure-material-checkbox > input:disabled + span::before {
    border-color: currentColor;
}

.pure-material-checkbox > input:checked:disabled + span::before,
.pure-material-checkbox > input:indeterminate:disabled + span::before {
    border-color: transparent;
    background-color: currentColor;
}
`;

const StyledTitle = styled.div`
  color: #888888;
`;

const StyledTextArea = styled.textarea`
  color: #838486;
  background: transparent;
  font-size: 16px;
  width: 100%;
  border: none;
  margin-top: 15px;
  resize: none;
  outline: none!important;
  &:hover {
    outline: none;
  }
`;

const StyledButton = styled.div`
  width: 100%;
  height: 45px;
  background: ${props => (props.disabled ? '#4e4f50' : '#838486')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items; center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 42px;
  font-weight: bold;
  color: #D3D3D5;
  border-radius: 5px;
  @media(max-width: 1024px) {
    margin-bottom: 30px;
  }
`;

const StyledClose = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 10px;
  right: 10px;
  opacity: 0.3;
  cursor: pointer;
  
  &:hover {
    opacity: 1;
  }
  &:before, &:after {
    position: absolute;
    left: 7px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #838486;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;


class ContactMe extends React.Component {
  constructor () {
    super();
    this.state = {
      showModal: false,
      name: '',
      email: '',
      message: '',
      notRobot: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal () {
    this.setState({ showModal: true });
  }

  handleCloseModal () {
    this.setState({ showModal: false });
  }

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  send = async () => {
    if(this.state.message.length > 512)
    {
      toast.error('You message is too long(max 512)');
      return;
    }
    if(!this.state.notRobot)
    {
      toast.error('Please check not robot');
      return;
    }
    if(!this.state.name)
    {
      toast.error('Please enter your name');
      return;
    }
    if(!this.state.email)
    {
      toast.error('Please enter your email');
      return;
    }
    if(!this.validateEmail(this.state.email))
    {
      toast.error('Please enter valid email');
      return;
    }
    if(!this.state.message)
    {
      toast.error('Please enter your message');
      return;
    }
    const messageParams = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    const result = await emailjs.send("gmail",'template_3tolQd8b', messageParams, 'user_foFaggDTleIUvQxC5Q53u');
    toast.success('Thank you!');
    this.handleCloseModal();
  };


  render () {
    return (
      <React.Fragment>
        {this.state.showModal && (
          <Helmet
            bodyAttributes={{ style: 'background-color: #7C7C7C' }}
          />
        )}
        <span onClick={this.handleOpenModal}>{this.props.children}</span>
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="Inline Styles Modal Example"
          style={{
            overlay: {
              backgroundColor: 'transparent',
              zIndex: 999,
              top: window.screen.width >= 1024 ? '27.5%' : '12.5%',
              left: window.screen.width >= 1024 ? '21.5%' : '7.5%',
              right: window.screen.width >= 1024 ? '21.5%' : '7.5%',
              bottom: window.screen.width >= 1024 ? '27.5%' : '12.5%',
            },
            content: {
              background: '#E9EAEC',
              width: '100%',
              height: '100%',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              boxSizing: 'border-box',
              border: '12.5px solid #888888',
              borderRadius: '12.5px',
              padding: '20px 0',
            }
          }}
        >
          <StyledContentWrapper>
            <StyledClose onClick={() => this.setState({showModal: false})}/>
            <StyledInfo>
              <Title>
                Contact me
              </Title>
              <StyledInput>
                <input type="text" required value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Name</label>
              </StyledInput>
              <StyledInput>
                <input type="text" required value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>Email</label>
              </StyledInput>
              <StyledCheckbox>
                <label className="pure-material-checkbox">
                  <input type="checkbox" value={this.state.notRobot} onChange={(e) => this.setState({notRobot: e.target.checked})}/>
                    <span>I am not a robot</span>
                </label>
              </StyledCheckbox>
            </StyledInfo>
            <StyledDivider/>
            <StyledDescription>
              <StyledTitle>
                Message
              </StyledTitle>
              <StyledTextArea rows={10} placeholder='Write text here...' value={this.state.message} onChange={(e) => this.setState({message: e.target.value})}/>
              <StyledButton onClick={this.send} disabled={!this.state.notRobot}>
                Send Message
              </StyledButton>
            </StyledDescription>
          </StyledContentWrapper>

        </ReactModal>
      </React.Fragment>
    );
  }
}


export default ContactMe;