import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import loader from "../../assets/icons/loader.gif";
import ServiceHeader from "../ServiceHeader";
import { ReactComponent as IconLogo } from "../../assets/icons/logo.svg";

const StyledIndex = styled.section`
  min-height: 80vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & img {
    width: 100px;
  }
`;

const StyledText = styled.h1`
  color: #fff;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
  text-decoration: none;
  display: ${(props) => (props.logomoved ? "block" : "none")};
`;

const StyledLogo = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  & svg {
    filter: brightness(0) invert(1);
    width: ${(props) => (props.logomoved ? "100px" : "50px")};
  }
`;

const Preloader = ({ children }) => {
  const [logoMoved, setLogoMoved] = useState(true);
  const [logoMovedAbout, setLogoMovedAbout] = useState(true);
  const [slideLogo, setSlideLogo] = useState(true);
  const [slideLogoAbout, setSlideLogoAbout] = useState(true);
  const [hideText, setHideText] = useState(true);
  const [hideTextAbout, setHideTextAbout] = useState(true);
  const [pdfLoaded, setPdfLoaded] = useState(false);

  useEffect(() => {
    if (logoMoved) {
      let timerId = setTimeout(function tick() {
        const isPdfLoadedT =
          window.localStorage.getItem("pdfLoaded") === "true";
        if (isPdfLoadedT) {
          setPdfLoaded(true);
          setTimeout(
            () => setLogoMoved(false),
            window.location.pathname === "/architecture" ? 1500 : 0
          );
          setTimeout(
            () => setSlideLogo(false),
            window.location.pathname === "/architecture" ? 3000 : 1000
          );
        } else {
          timerId = setTimeout(tick, 2500);
        }
      }, 1000);
    }
  }, [logoMoved]);

  useEffect(() => {
    if (hideText) {
      let timerId1 = setTimeout(function tick() {
        const isPdfLoadedT =
          window.localStorage.getItem("pdfLoaded") === "true";
        if (isPdfLoadedT) {
          setTimeout(
            () => setHideText(false),
            window.location.pathname === "/architecture" ? 5000 : 2000
          );
        } else {
          timerId1 = setTimeout(tick, 2500);
        }
      }, 2000);
    }
  }, [hideText]);

  useEffect(() => {
    if (hideTextAbout) {
      setTimeout(() => setHideTextAbout(false), 0);
    }
  }, [hideTextAbout]);

  useEffect(() => {
    if (slideLogoAbout) {
      setTimeout(() => setSlideLogoAbout(false), 1500);
    }
  }, [slideLogoAbout]);

  useEffect(() => {
    if (logoMovedAbout) {
      setTimeout(() => setLogoMovedAbout(false), 500);
    }
  }, [logoMovedAbout]);

  const spring = {
    type: "spring",
    damping: 100,
    stiffness: 40,
    delay: 1,
  };

  return (
    <StyledIndex>
      {window.location.pathname === "/about" ? (
        <React.Fragment>
          <motion.div
            className="test"
            initial={{
              opacity: !logoMoved ? 0 : 1,
            }}
            animate={{ opacity: !logoMoved ? 1 : 1 }}
            transition={{ duration: 2 }}
            style={
              slideLogoAbout
                ? {
                    position:
                      window.location.pathname === "/" ? "inherit" : "absolute",
                    top: window.location.pathname === "/" ? "inherit" : "-50px",
                    left:
                      window.location.pathname === "/" ? "inherit" : "-50px",
                  }
                : {
                    position: "absolute",
                    top: "30px",
                    left: "30px",
                    zIndex: "60",
                  }
            }
            positionTransition={spring}
          >
            {!logoMovedAbout ? (
              <motion.div
                initial={{
                  scale: 0.2,
                  opacity: 0,
                }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0, duration: 2 }}
              >
                <StyledLogo to="/" logomoved={hideTextAbout ? 1 : 0}>
                  <IconLogo />
                  <StyledText logomoved={hideTextAbout ? 1 : 0}>
                    Da Guo
                  </StyledText>
                </StyledLogo>
              </motion.div>
            ) : null}
          </motion.div>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, delay: 0 }}
          >
            {children}
          </motion.div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <motion.div
            className="test"
            initial={{
              opacity: !logoMoved ? 0 : 1,
            }}
            animate={{ opacity: !logoMoved ? 1 : 1 }}
            transition={{ duration: 1 }}
            style={
              slideLogo
                ? {
                    position:
                      window.location.pathname === "/" ? "inherit" : "absolute",
                    top: window.location.pathname === "/" ? "inherit" : "-50px",
                    left:
                      window.location.pathname === "/" ? "inherit" : "-50px",
                  }
                : {
                    position: "absolute",
                    top: "30px",
                    left: "30px",
                    zIndex: "60",
                  }
            }
            positionTransition={spring}
          >
            {!logoMoved ? (
              <motion.div
                initial={{
                  scale: 0.2,
                  opacity: 0,
                }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  delay: window.location.pathname === "/architecture" ? 0 : 0,
                  duration: window.location.pathname === "/" ? 1 : 0,
                }}
              >
                <StyledLogo to="/" logomoved={hideText ? 1 : 0}>
                  <IconLogo />
                  <StyledText logomoved={hideText ? 1 : 0}>Da Guo</StyledText>
                </StyledLogo>
              </motion.div>
            ) : window.location.pathname === "/" ? (
              <React.Fragment>
                {pdfLoaded ? (
                  <motion.img
                    initial={{
                      top: window.location.pathname === "/" ? 0 : 50,
                    }}
                    animate={{ top: "-100vh" }}
                    transition={{ delay: 0, duration: 0.5 }}
                    style={{
                      position: "absolute",
                      left: window.location.pathname === "/" ? 0 : 50,
                      top: 0,
                      zIndex: 90000000,
                      width: "100vw",
                      height: "100vh",
                    }}
                    src={loader}
                    alt="loading..."
                  />
                ) : (
                  <motion.img
                    initial={{
                      top: window.location.pathname === "/" ? 0 : 50,
                    }}
                    animate={{
                      top: window.location.pathname === "/" ? 0 : 50,
                    }}
                    transition={{ delay: 0, duration: 1.5 }}
                    style={{
                      position: "absolute",
                      left: window.location.pathname === "/" ? 0 : 50,
                      top: 0,
                      zIndex: 90000000,
                      width: "100vw",
                      height: "100vh",
                    }}
                    src={loader}
                    alt="loading..."
                  />
                )}
              </React.Fragment>
            ) : null}
          </motion.div>

          {window.location.pathname === "/architecture" ? (
            <React.Fragment>
              <motion.div></motion.div>
              {pdfLoaded ? (
                <motion.div
                  style={{ width: "100%" }}
                  initial={{
                    scale: 0,
                    height: 0,
                  }}
                  animate={{ scale: 1, height: "auto" }}
                  transition={{ duration: 1.5, delay: 0 }}
                >
                  {children}
                </motion.div>
              ) : (
                <motion.div
                  style={{ width: "100%" }}
                  initial={{
                    scale: 0,
                    height: 0,
                  }}
                  animate={{ scale: 1, height: "auto" }}
                  transition={{ duration: 1.5, delay: 0 }}
                >
                  {children}
                </motion.div>
              )}
            </React.Fragment>
          ) : (
            <motion.div
              style={{ width: "100%" }}
              initial={{
                scale: 0,
                height: 0,
              }}
              animate={{ scale: 1, height: "auto" }}
              transition={{
                duration: 0,
                delay: window.location.pathname === "/" ? 5 : 0,
              }}
            >
              {children}
            </motion.div>
          )}
        </React.Fragment>
      )}
    </StyledIndex>
  );
};

export default Preloader;
