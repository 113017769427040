import React, { useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { motion } from "framer-motion";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import github from "../../assets/icons/github.png";
import heroku from "../../assets/icons/heroku.png";

const StyledData = styled(motion.div)`
  cursor: pointer;
  height: ${(props) => props.height};
  margin: ${(props) =>
    props.height === "330px" ? "0px 20px 40px 20px" : "0px 20px 20px 20px"};
  background-color: white;
  background: #fff;
  color: #58595b;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  transition: all 0.2s;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  &:hover {
    background-image: none;
    box-shadow: 10px 10px 15px -10px rgba(88, 89, 91, 0.45);
    &:before {
      opacity: 0;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all 1s;
    background-size: cover;
    background-position: center;
    background-image: url('${props => props.image}');
  }
`;

const StyledText = styled.span`
  z-index: 200;
  color: black;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
  height: 100%;
`;

const StyledContentImage = styled.div`
  width: 100%;
  height: 550px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {    
    content: "";
    background-image: url('${(props) => props.image}');
    background-color: white;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 1;
}
`;

const pixelsToMove = 100;

const cssVisible = `
  opacity: 1;
  top: 0;
`;
const cssHidden = `
  opacity: 0;
`;

const StyledTitle = styled.h3`
  color: #000;
  font-size: 22px;
  font-weight: 500;
  position: relative;
  transition: all 1s;
  margin: 0;
  margin-bottom: 15px;
  ${(props) =>
    !props.ishover
      ? `
      ${cssVisible}
  `
      : `position: absolute;
      top: -${pixelsToMove}px;
      ${cssHidden}`}
`;

const StyledDescription = styled.div`
  color: #000;
  font-size: 16px;
  max-width: 90%;
  max-height: 80%;
  position: relative;
  transition: all 0.7s;
  color: #a0a1a0;
  ${(props) =>
    props.ishover
      ? `
      ${cssVisible}
      position: relative;
      transform: scale(1);
      `
      : `
  ${cssHidden}
      position: absolute;
      transform: scale(0);
  `};
  @media (max-width: 768px) {
    position: relative;
    opacity: 1;
    transform: scale(1);
  }
`;

// Modal
Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    zIndex: "30",
    background: "rgba(0,0,0,0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "2rem",
    position: "fixed",
    minWidth: "20rem",
    width: "50%",
    maxWidth: "80rem",
    minHeight: "5rem",
    height: "90vh",
    maxHeight: "60rem",
    borderRadius: "20px",
  },
};

const StyledClose = styled.button`
  z-index: 301;
  background: transparent;
  border: 1px solid transparent;
  font-size: 35px;
  font-weight: 500;
  position: fixed;
  top: 15px;
  right: 15px;
  color: #58595b;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    border-color: #eee;
  }
`;

const StyledModal = styled(Modal)`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  overflow-y: scroll;
  background: #fff;
  &:focus {
    outline: 0 !important;
  }
`;

const StyledModalContent = styled.section`
  color: #58595b;
  font-size: 22px;
  font-weight: 500;
  background: #fff;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  // padding-top: 30px;
  & h2 {
    text-align: center;
    margin-top: 0;
    padding-top: 0;
  }
  height: 100%;
`;

const StyledContent = styled.section`
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  position: relative;
  div {
    color: black;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    width: 100%;
    padding: 0;
  }
`;

const StyledIcon = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-right: 10px;
  display: inline-block;

  > img {
    width: 30px;
    height: 30px;
    max-width: 100%;
    margin-right: 10px;
  }

  > span {
    font-size: 15px;
  }
`;

const StyledTextDiv = styled.div`
  z-index: 201;
`;

const StyledContentText = styled.p`
  text-align: center;
  margin: 0 !important;
  color: black;
`;

const Medium = ({
  height,
  description,
  previewImage,
  image,
  no,
  title,
  link,
  gitlink,
  livelink,
  modal,
  modal_desc,
  isOpened,
}) => {
  const [isHover, setHover] = useState(false);
  const [modalOpen, setModalOpen] = useState(isOpened);

  const history = useHistory();

  const openModal = () => {
    if (modal) {
      setModalOpen(true);
      // history.push(`/data/${no}`);
    }
  };
  const closeModal = () => {
    setModalOpen(false);
    // setTimeout(() => {
    //   history.push(`/data/`);
    // }, 1000);
  };

  return (
    <>
      <StyledData
        onHoverStart={() => {
          setHover(true);
        }}
        onHoverEnd={() => {
          setHover(false);
        }}
        height={height}
        image={`/assets/images/${previewImage}`}
        onClick={openModal}
      >
        <StyledTitle ishover={isHover ? 0 : 1}>{no}</StyledTitle>
        <StyledDescription ishover={isHover ? 1 : 0}>
          {parse(description)}
        </StyledDescription>
        {previewImage === "" ? (
          <StyledText>PLACEHOLDER FOR PROJECT5</StyledText>
        ) : null}
      </StyledData>
      <StyledModal
        isOpen={modalOpen}
        onAfterOpen={() => {}}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
      >
        {modalOpen && <StyledClose onClick={closeModal}>&times;</StyledClose>}
        <StyledModalContent>
          <a
            style={{ textDecoration: "none", width: "100%" }}
            target="blank"
            href={link}
          >
            <StyledContentImage image={`/assets/images/${image}`}>
              {/* <StyledTextDiv>
                <StyledContentText>
                  Medium post snap shot here, If I click
                </StyledContentText>
                <StyledContentText>
                  on this it should take me to the
                </StyledContentText>{" "}
                <StyledContentText> actual blog page.</StyledContentText>
              </StyledTextDiv> */}
            </StyledContentImage>
          </a>
          <StyledContent>
            <div style={{ marginBottom: "10px" }}>{modal_desc}</div>
            <a href={gitlink}>
              <StyledIcon>
                <img src={github} />
                <span>GitHub</span>
              </StyledIcon>
            </a>
            <a href={livelink}>
              <StyledIcon>
                <img src={heroku} />
                <span>Live Deployment</span>
              </StyledIcon>
            </a>
          </StyledContent>
        </StyledModalContent>
      </StyledModal>
    </>
  );
};

export default Medium;
