import React from 'react';
import styled from 'styled-components';
// import { NavLink } from 'react-router-dom';
import Helmet from 'react-helmet';
import { motion } from 'framer-motion';
import contact from '../assets/sidebar_3.png';
import ContantMe from '../components/ContactMe/ContactMe';
// import myImage from '/assets/images/DaGuo_portrait.jpg';

// const StyledButton = styled(NavLink)`
//   background: transparent;
//   border: 0;
//   text-decoration: none;
//   font-size: 24px;
//   font-weight: 500;
//   color: #000;
//   text-align: center;
//   display: block;
//   width: 300px;
//   padding: 10px 20px;
//   margin: 30px auto 0;
// `;

const Wrapper = styled.div`
  min-height: 80vh;
  width: 80wv;
  @media (min-width: 1919px) {
    max-width: 1536px;
  }
  margin-left: 15px;
  margin-right: 15px;
`;

const Title = styled.div`
  // width: 80vw;
  max-width: 80%;
  margin-left: auto;
    margin-right: auto;
}
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  justify-content: space-between;
  // border: 1px solid red;
  padding: 0 5%;
  
`;

const StyledPagination = styled.div`
  width: 100%;
  max-width: 100%;
  text-align: center;
  color: #000;
  margin-top: 20px;
`;

const StyledButton = styled.a`
  background: transparent;
  border: 1px solid #000;
  margin: 0 10px;
  color: #000;
  text-decoration: none!important;
  font: 400 11px system-ui;
  padding: 1px 7px 2px;
  cursor: pointer;
`;

const Photo = styled.div`
  width: 353px;
  height: 331px;
  background-image: url("/assets/images/DaGuo_portrait2.jpg");
`;

const Info = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // border: 1px solid blue;
`;

const InfoCardWrapper = styled.div`
  width: 33%;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const InfoCard = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  max-height: 400px;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
  display: none;
  }
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // border: 1px solid blue;
  margin-top: 20px;
`;
 
const InfoTitle = styled.h1`
color: #848484;
  font-size: 28px;
    margin-block-end: -10px;
  @media (max-width: 768px) {
    margin-block-end: -10px;
  }
`;


const Description = styled.div`
  color: #222;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  overflow-y: scroll;
  min-height: 180px;
`;

const Parags = styled.div`
  // width: 80vw;
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  justify-content: space-between;
  // border: 1px solid red;
  margin-bottom: 100px;
`;

const About = () => {
  window.localStorage.setItem('pdfLoaded', 'true');
  return (
    <Wrapper>
      <Helmet>
        <title>Da Guo</title>
      </Helmet>
      <Title>
        <Photo/>
        <Info>
          <InfoTitle>
            Da Guo
          </InfoTitle>
          <Description>
            <p style={{fontSize: 20}}>Hello! I am a data scientist inspired to help making informed decisions through data. My diverse background spans across multiple industries. I enjoy designing, managing, analyzing, developing, deploying, marketing, and maintaining high quality data science projects.</p>
            <StyledPagination>
              <StyledButton href='https://www.github.com/opophehu'>GitHub</StyledButton>
              <StyledButton target="blank" href='https://www.linkedin.com/in/da-guo'>Linkedin</StyledButton>
              <ContantMe><StyledButton>Contact me</StyledButton></ContantMe>
            </StyledPagination>
          </Description>
        </Info>
      </Title>
      <Parags>
        <InfoCardWrapper>
          <InfoCard>
            <InfoTitle>
              Education
            </InfoTitle>
            <Description>
              <br></br>
              <ul>
                <li><p style={{fontSize: 14}}><span style={{fontSize: 18, fontWeight: "bold"}}>Data Science Stack</span>, <br></br>Metis (Bootcamp), 2020-2020</p></li>
                <li><p style={{fontSize: 14}}><span style={{fontSize: 18, fontWeight: "bold"}}>Full Stack Web Development</span>, <br></br>Coding Dojo (Bootcamp), 2019-2020</p></li>
                <li><p style={{fontSize: 14}}><span style={{fontSize: 18, fontWeight: "bold"}}>Master of Architecture</span>, <br></br>Washington State University, 2017-2019</p></li>
                <li><p style={{fontSize: 14}}><span style={{fontSize: 18, fontWeight: "bold"}}>B.S. in Architectural Studies</span>, <br></br>Washington State University, 2013-2017</p></li>
                <li><p style={{fontSize: 14}}><span style={{fontSize: 18, fontWeight: "bold"}}>Minor in Construction Management</span>, <br></br>Washington State University, 2015-2015</p></li>
              </ul>
            </Description>
          </InfoCard>
        </InfoCardWrapper>
        <InfoCardWrapper>
          <InfoCard>
            <InfoTitle>
              Skills
            </InfoTitle>
            <Description>
            <br></br>
            <ul>
              <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Programming Languages: 
                  </span><br></br><span style={{fontSize: 15}}>Python, C#, JavaScript, HTML/CSS</span></p>
              </li>

              <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Data Science: 
                  </span><br></br><span style={{fontSize: 15}}>Pandas, Numpy, Scikit-learn, Tensorflow, Keras, NLTK</span></p>
              </li>

              <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Web Dev Frameworks: 
                  </span><br></br><span style={{fontSize: 15}}>Flask, Django, React, Angular, Nodejs, ASP.NET Core</span></p>
              </li>

              <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Visualization: 
                  </span><br></br><span style={{fontSize: 15}}>Adobe Creative Suite, Blender, Matplotlib, Seaborn, Tableau, Plotly, Dashboard, Bokeh</span></p>
              </li>

              <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Architecture: 
                  </span><br></br><span style={{fontSize: 15}}>Rhino3D, Grasshopper, Sketchup, AutoCAD, Lumion Render, Weather Consultant, Sefaira</span></p>
              </li>
            </ul>
            </Description>
          </InfoCard>
        </InfoCardWrapper>
        <InfoCardWrapper>
          <InfoCard>
            <InfoTitle>
              Honors & Awards
            </InfoTitle>
            <Description>
            <br></br>
              <ul>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>AIA Henry Adams Medal - Nomination</span>, <br></br>Washington State University, 2019</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Capstone Competition - Third Place</span>, <br></br>Washington State University, 2016</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>WSU Solar Decathlon 2017 - Top 10 Design</span>, <br></br>Washington State University, 2015</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 14, fontWeight: "bold"}}>WSU SDC Portfolio Competition - Third Place</span>, <br></br>Washington State University, 2015</p></li>
              </ul>
            </Description>
          </InfoCard>
        </InfoCardWrapper>
        <InfoCardWrapper>
          <InfoCard>
            <InfoTitle>
              Publications
            </InfoTitle>
            <Description>
              
            <br></br>
              <ul>
                <li>
                  <p style={{fontSize: 15}}>
                    <a style={{color: 'inherit', textDecoration: 'inherit'}} href="https://medium.com/@opophehu/placeholder-for-traffic-congestion-972873feee8a">
                    <span style={{fontSize: 15, fontWeight: "bold"}}>Anomaly Detection: Traffic Congestion Duration</span></a>, 
                    <br></br>
                    Medium, 2020
                  </p>
                </li>
                <li>
                  <p style={{fontSize: 15}}>
                    <a style={{color: 'inherit', textDecoration: 'inherit'}} href="https://medium.com/ai-in-plain-english/unsupervised-machine-learning-clustering-analyzing-covid-19-mask-sentiment-in-the-usa-a104170ad9a7">
                    <span style={{fontSize: 15, fontWeight: "bold"}}>Unsupervised Machine Learning Clustering: Analyzing Covid-19 Mask Sentiment in the USA</span></a>, 
                    <br></br>
                    Medium - AI in Plain English, 2020
                  </p>
                </li>
                <li>
                  <p style={{fontSize: 15}}>
                    <a style={{color: 'inherit', textDecoration: 'inherit'}} href="https://medium.com/ai-in-plain-english/supervised-machine-learning-classification-predicting-possibility-of-steam-game-to-be-on-sale-a0043d0c5ba5">
                    <span style={{fontSize: 15, fontWeight: "bold"}}>Supervised Machine Learning Classification: Predicting Possibility of Steam Game to be On Sale</span></a>, 
                    <br></br>
                    Medium - AI in Plain English, 2020
                  </p>
                </li>
                <li>
                  <p style={{fontSize: 15}}>
                    <a style={{color: 'inherit', textDecoration: 'inherit'}} href="https://medium.com/analytics-vidhya/linear-regression-predicting-the-magnitude-of-steam-discount-1cd77591fcaa">
                    <span style={{fontSize: 15, fontWeight: "bold"}}>Linear Regression: Predicting the Magnitude of Steam discount</span></a>, 
                    <br></br>
                    Medium - Analytics Vidhya, 2020
                  </p>
                </li>
                <li>
                  <p style={{fontSize: 15}}>
                    <a style={{color: 'inherit', textDecoration: 'inherit'}} href="https://medium.com/analytics-vidhya/exploratory-data-analysis-maximizing-audience-reach-for-women-in-tech-398099e89d0">
                    <span style={{fontSize: 15, fontWeight: "bold"}}>Exploratory Data Analysis: Maximizing Audience Reach for Women in Tech</span></a>, 
                    <br></br>
                    Medium - Analytics Vidhya, 2020
                  </p>
                </li>
                <li>
                  <p style={{fontSize: 15}}>
                    <a style={{color: 'inherit', textDecoration: 'inherit'}} href="#">
                    <span style={{fontSize: 15, fontWeight: "bold"}}>66 Damn Good Site Positions</span></a>, 
                    <br></br>
                    WSU - School of Design and Construction, 2019
                  </p>
                </li>
              </ul>
            </Description>
          </InfoCard>
        </InfoCardWrapper>
        <InfoCardWrapper>
          <InfoCard>
            <InfoTitle>
              Hobbies
            </InfoTitle>
            <Description>
            <br></br>
              <ul>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Video Games</span>, <br></br>All genre, casual or competitive</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Model Making</span>, <br></br>Buildings, Gundams, etc.</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Reading</span>, <br></br> Thought provoking, horror, history, fiction, etc.</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Racquet Sports</span>, <br></br> Table tennis, tennis, badminton</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Traveling</span>, <br></br>France, Japan, Jordan, China</p></li>
              </ul>
            </Description>
          </InfoCard>
        </InfoCardWrapper>
        <InfoCardWrapper>
          <InfoCard>
            <InfoTitle>
              Favorite Shows
            </InfoTitle>
            <Description>
            <br></br>
              <ul>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>House of Cards (2013)</span>, <br></br> Season 1 & 2</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Neon Genesis Evangelion (1995)</span>, <br></br> TV series</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Shutter Island (2010)</span>, <br></br> Movie</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Donnie Darko (2001)</span>, <br></br> Movie</p></li>
                <li><p style={{fontSize: 15}}><span style={{fontSize: 15, fontWeight: "bold"}}>Band of Brothers (2001)</span>, <br></br>Miniseries</p></li>
              </ul>
            </Description>
          </InfoCard>
        </InfoCardWrapper>
      </Parags>
    </Wrapper>
  );
};

export default About;
